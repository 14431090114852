/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

type As<Props = any> = React.ElementType<Props>

type PropsWithAs<Props = Record<string, unknown>, Type extends As = As> =
  Props & Omit<React.ComponentProps<Type>, 'as' | keyof Props> & {as?: Type}

type ComponentWithAs<Props, DefaultType extends As> = {
  <Type extends As>(props: PropsWithAs<Props, Type> & {as: Type}): JSX.Element
  (props: PropsWithAs<Props, DefaultType>): JSX.Element
}

function forwardRefWithAs<Props, DefaultType extends As>(
  component: React.ForwardRefRenderFunction<any, any>,
) {
  return React.forwardRef(component) as unknown as ComponentWithAs<
    Props,
    DefaultType
  >
}

export type {As, PropsWithAs}
export {forwardRefWithAs}
