import type {PropsWithAs} from '../utils'
import {forwardRefWithAs} from '../utils'

import {
  clickableBaseStyles,
  clickableSizeStyles,
  clickableStyles,
} from './clickable-styles'

type ClickableProps = {
  variant?: keyof typeof clickableStyles
  size?: keyof typeof clickableSizeStyles
}

function ClickableComponent(
  {
    as: Component = 'button',
    variant,
    size,
    ...props
  }: PropsWithAs<ClickableProps, 'button'>,
  ref: React.Ref<HTMLButtonElement>,
) {
  return (
    <Component
      ref={ref}
      css={[
        clickableBaseStyles,
        variant && clickableStyles[variant],
        size && clickableSizeStyles[size],
      ]}
      {...props}
    />
  )
}

/**
 * Cosmetic-free interactive component. We can also use it for links. It's
 * common to use predefined size so we make size prop available at this level
 */
const Clickable = forwardRefWithAs<ClickableProps, 'button'>(ClickableComponent)

export type {ClickableProps}
export {Clickable}
