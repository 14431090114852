import tw from 'twin.macro'

type SpacerProps =
  | {
      x: keyof typeof horizontalSpacing
      y?: never
    }
  | {
      x?: never
      y: keyof typeof verticalSpacing
    }

const horizontalSpacing = {
  auto: tw`mx-auto`,
  container: tw`pl-container`,
  px: tw`pl-px`,
  0.5: tw`pl-0.5`,
  1: tw`pl-1`,
  2: tw`pl-2`,
  3: tw`pl-3`,
  4: tw`pl-4`,
  5: tw`pl-5`,
  6: tw`pl-6`,
  7: tw`pl-7`,
}

const verticalSpacing = {
  auto: tw`my-auto`,
  container: tw`pt-container`,
  px: tw`pt-px`,
  0.5: tw`pt-0.5`,
  1: tw`pt-1`,
  2: tw`pt-2`,
  3: tw`pt-3`,
  4: tw`pt-4`,
  5: tw`pt-5`,
  6: tw`pt-6`,
  7: tw`pt-7`,
}

/**
 * Space out elements
 */
function Spacer({x, y}: SpacerProps) {
  if (x && y) throw new Error(`Only x or y prop is allowed in Spacer.`)
  if (x) return <span css={horizontalSpacing[x]} />
  if (y) return <div css={verticalSpacing[y]} />
  throw new Error(`Provide x or y prop in Spacer.`)
}

export type {SpacerProps}
export {Spacer}
