import 'twin.macro'

import type {ClickableProps} from '../clickable'
import {Clickable, clickableStyles} from '../clickable'
import type {PropsWithAs} from '../utils'
import {forwardRefWithAs} from '../utils'

import {
  buttonBaseStyles,
  buttonStyles,
  iconButtonSizeStyles,
} from './button-styles'

type ButtonProps = Omit<ClickableProps, 'variant'> & {
  variant?: keyof typeof buttonStyles | keyof typeof clickableStyles
} & (
    | {
        children: React.ReactNode
        prefix?: keyof JSX.IntrinsicElements | React.ComponentType | false
        suffix?: keyof JSX.IntrinsicElements | React.ComponentType | false
        icon?: never
      }
    | {
        children?: never
        prefix?: never
        suffix?: never
        icon: keyof JSX.IntrinsicElements | React.ComponentType
        'aria-label': string
      }
  )

function ButtonComponent(
  props: PropsWithAs<ButtonProps, typeof Clickable>,
  ref: React.Ref<HTMLButtonElement>,
) {
  const {
    as = 'button',
    variant: variantProp,
    size = 'md',
    prefix: PrefixIcon,
    suffix: SuffixIcon,
    icon: Icon,
    children,
    ...otherProps
  } = props

  const variant = variantProp ?? (Icon ? 'transparent' : 'primary')

  const clickableVariant = Object.keys(clickableStyles).includes(variant)
    ? (variant as keyof typeof clickableStyles)
    : undefined

  const buttonVariant = Object.keys(buttonStyles).includes(variant)
    ? (variant as keyof typeof buttonStyles)
    : undefined

  const styles = [
    buttonBaseStyles,
    buttonVariant && buttonStyles[buttonVariant],
  ]

  if (Icon) {
    return (
      <Clickable
        as={as}
        ref={ref}
        variant={clickableVariant}
        css={[...styles, iconButtonSizeStyles[size]]}
        {...otherProps}
      >
        <Icon />
      </Clickable>
    )
  }

  return (
    <Clickable
      as={as}
      ref={ref}
      variant={clickableVariant}
      size={size}
      css={styles}
      {...otherProps}
    >
      {PrefixIcon && <PrefixIcon tw="mr-1 -ml-0.5" />}
      {children}
      {SuffixIcon && <SuffixIcon tw="ml-1 -mr-0.5" />}
    </Clickable>
  )
}

/**
 * Primary UI component for user interaction
 */
const Button = forwardRefWithAs<ButtonProps, typeof Clickable>(ButtonComponent)

export type {ButtonProps}
export {Button}
