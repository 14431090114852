import tw from 'twin.macro'

/**
 * Make button ready to consume custom styles
 */
const buttonBaseStyles = tw`text-center inline-flex items-center justify-center border border-transparent select-none`

/**
 * Icon button size styles
 */
const iconButtonSizeStyles = {
  none: null,
  md: tw`padding[4px]`,
  lg: tw`padding[8px]`,
}

/**
 * Button variant styles
 */
const buttonStyles = {
  none: null,
  primary: tw`font-medium text-background bg-primary hover:bg-secondary`,
  outline: tw`font-medium border-muted hover:border-primary`,
}

export {buttonBaseStyles, buttonStyles, iconButtonSizeStyles}
