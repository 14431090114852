import tw from 'twin.macro'

/**
 * Apply styles for clickable elements
 */
const clickableBaseStyles = tw`text-left rounded focus:outline-none disabled:(opacity-50 pointer-events-none) focus-visible:(ring-4 ring-highlight)`

/**
 * Clickable size styles
 */
const clickableSizeStyles = {
  none: null,
  md: tw`padding-right[8px] padding-left[8px] padding-top[4px] padding-bottom[4px]`,
  lg: tw`padding-right[12px] padding-left[12px] padding-top[8px] padding-bottom[8px]`,
}

/**
 * Clickable variant styles
 */
const clickableStyles = {
  transparent: tw`hover:bg-washed focus-visible:bg-washed`,
  underline: tw`underline font-medium hover:text-gray`,
  nav: tw`text-gray hover:text-text`,
  navIcon: tw`text-gray bg-washed hover:bg-muted`,
}

export {clickableBaseStyles, clickableSizeStyles, clickableStyles}
