import tw from 'twin.macro'

const cardBaseStyles = tw`overflow-hidden bg-background rounded`

const cardStyles = {
  focus: tw`rounded-xl p-4 box-shadow[rgb(0 0 0 / 20%) 0px 0px 1px, rgb(0 0 0 / 6%) 0px 20px 40px] opacity-95`,
  comment: tw`rounded-xl p-2 box-shadow[rgb(0 0 0 / 20%) 0px 0px 1px, rgb(0 0 0 / 6%) 0px 20px 40px]`,
  page: tw`rounded-xl p-3 sm:(rounded-2xl p-4) md:(rounded-3xl p-5)`,
}

/**
 * Card with variants for specific use cases
 */
function Card({
  as: Component = 'div',
  variant,
  ...props
}: {
  as?: keyof JSX.IntrinsicElements | React.ComponentType
  variant?: keyof typeof cardStyles
  children: React.ReactNode
}) {
  return (
    <Component
      css={[cardBaseStyles, variant && cardStyles[variant]]}
      {...props}
    />
  )
}

export {Card}
